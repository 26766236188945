import * as React from 'react'

function InstagramIcon(props) {
    const { color = 'url(#prefix__a)' } = props
    return (
        <svg width={40} height={40} viewBox="0 0 40 40">
            <defs>
                <linearGradient
                    id="prefix__a"
                    x1={0.068}
                    y1={0.061}
                    x2={0.943}
                    y2={0.922}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#f2666b" />
                    <stop offset={1} stopColor="#ff9d64" />
                </linearGradient>
            </defs>
            <path
                d="M22.416 6.607a8.253 8.253 0 00-.523-2.729 5.51 5.51 0 00-1.3-1.991A5.511 5.511 0 0018.605.59a8.252 8.252 0 00-2.729-.522C14.677.013 14.294 0 11.241 0S7.805.013 6.607.067A8.254 8.254 0 003.878.59a5.51 5.51 0 00-1.991 1.3A5.511 5.511 0 00.59 3.878a8.252 8.252 0 00-.522 2.728C.013 7.805 0 8.188 0 11.241s.013 3.436.068 4.635A8.251 8.251 0 00.59 18.6a5.509 5.509 0 001.3 1.991 5.508 5.508 0 001.991 1.3 8.249 8.249 0 002.729.523c1.2.055 1.582.068 4.635.068s3.436-.013 4.635-.068a8.25 8.25 0 002.729-.523 5.747 5.747 0 003.284-3.291 8.25 8.25 0 00.523-2.729c.055-1.2.068-1.582.068-4.635s-.013-3.436-.068-4.635zm-2.024 9.178A6.22 6.22 0 0120 17.871a3.723 3.723 0 01-2.134 2.134 6.22 6.22 0 01-2.087.387c-1.185.054-1.541.066-4.543.066s-3.357-.011-4.543-.066a6.222 6.222 0 01-2.087-.387 3.484 3.484 0 01-1.293-.841 3.482 3.482 0 01-.841-1.292 6.22 6.22 0 01-.387-2.087c-.054-1.185-.066-1.541-.066-4.543s.011-3.357.066-4.543a6.224 6.224 0 01.387-2.087 3.485 3.485 0 01.841-1.293 3.481 3.481 0 011.293-.841A6.219 6.219 0 016.7 2.091c1.185-.054 1.541-.066 4.543-.066 3 0 3.357.011 4.543.066a6.221 6.221 0 012.087.387 3.486 3.486 0 011.293.841A3.481 3.481 0 0120 4.612a6.214 6.214 0 01.392 2.088c.054 1.185.066 1.541.066 4.543s-.012 3.357-.066 4.541zm0 0"
                transform="translate(8.47 8.885)"
                fill={color}
            />
            <path
                d="M130.312 124.539a5.773 5.773 0 105.773 5.773 5.773 5.773 0 00-5.773-5.773zm0 9.52a3.747 3.747 0 113.747-3.747 3.747 3.747 0 01-3.747 3.747zm0 0"
                transform="translate(-110.6 -110.185)"
                fill={color}
            />
            <path
                d="M364.628 89.974a1.349 1.349 0 11-1.349-1.349 1.349 1.349 0 011.349 1.349zm0 0"
                transform="translate(-337.566 -75.849)"
                fill={color}
            />
            <path fill="none" d="M0 0h40v40H0z" />
        </svg>
    )
}

export default InstagramIcon
