import * as React from 'react'

function TikTokIcon(props) {
    const { color = 'url(#prefix__a)' } = props
    return (
        <svg width={40} height={40} viewBox="0 0 40 40">
            <defs>
                <linearGradient
                    id="prefix__a"
                    x1={0.178}
                    x2={1}
                    y2={1}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#f2666a" />
                    <stop offset={1} stopColor="#ff9d64" />
                </linearGradient>
            </defs>
            <path
                d="M75.5 80.6a4.58 4.58 0 01-2.419-.74 4.928 4.928 0 01-.7-.588 4.149 4.149 0 01-.359-.4 4.841 4.841 0 01-.662-1.2 4.94 4.94 0 01-.258-1.591 4.419 4.419 0 01.53-2.025 4.787 4.787 0 011.048-1.285 4.337 4.337 0 011.229-.726 4.14 4.14 0 011.535-.325c.146 0 .269.005.378.013v1.857a3.01 3.01 0 00-.319-.019 2.374 2.374 0 00-1.41.436 2.627 2.627 0 00-1.142 1.948 2.819 2.819 0 00.5 1.74l.112.152h.013a2.514 2.514 0 00.46.415 2.6 2.6 0 001.468.492 2.95 2.95 0 001.11-.24 2.463 2.463 0 001.365-1.485 3.333 3.333 0 00.138-1.144v-9.77h1.881a3.953 3.953 0 001 2.3 4.275 4.275 0 001.195.918 4.385 4.385 0 00.971.354 3.626 3.626 0 00.476.083v1.823a7.311 7.311 0 01-1.139-.181 5.728 5.728 0 01-1.463-.6L80 70.152V76.2a4.988 4.988 0 01-.294 1.533 4.527 4.527 0 01-.793 1.32 4.676 4.676 0 01-1.526 1.147 4.288 4.288 0 01-1.777.4z"
                transform="translate(-57.563 -53.115)"
                fill={color}
            />
            <path fill="none" d="M0 0h40v40H0z" />
        </svg>
    )
}

export default TikTokIcon
